import React from 'react'
import MediaQuery from "react-responsive";
import portrait01 from '../assets/images/portrait/2015/01.jpg'
import portrait02 from '../assets/images/portrait/2015/02.jpg'
import portrait03 from '../assets/images/portrait/2015/03.jpg'
import portrait04 from '../assets/images/portrait/2015/04.jpg'
import portrait05 from '../assets/images/portrait/2015/05.jpg'
import portrait06 from '../assets/images/portrait/2015/06.jpg'
import portrait07 from '../assets/images/portrait/2015/07.jpg'
import portrait08 from '../assets/images/portrait/2015/08.jpg'
import portrait09 from '../assets/images/portrait/2015/09.jpg'
import portrait10 from '../assets/images/portrait/2015/10.jpg'
import portrait11 from '../assets/images/portrait/2015/11.jpg'
import portrait12 from '../assets/images/portrait/2015/12.jpg'
import portrait13 from '../assets/images/portrait/2015/13.jpg'
import portrait14 from '../assets/images/portrait/2015/14.jpg'
import portrait15 from '../assets/images/portrait/2015/15.jpg'
import portrait16 from '../assets/images/portrait/2015/16.jpg'
import portrait17 from '../assets/images/portrait/2015/17.jpg'
import portrait18 from '../assets/images/portrait/2015/18.jpg'
import portrait19 from '../assets/images/portrait/2015/19.jpg'
import thumb01 from '../assets/images/portrait/2015/01.jpg'
import thumb02 from '../assets/images/portrait/2015/02.jpg'
import thumb03 from '../assets/images/portrait/2015/03.jpg'
import thumb04 from '../assets/images/portrait/2015/04.jpg'
import thumb05 from '../assets/images/portrait/2015/05.jpg'
import thumb06 from '../assets/images/portrait/2015/06.jpg'
import thumb07 from '../assets/images/portrait/2015/07.jpg'
import thumb08 from '../assets/images/portrait/2015/08.jpg'
import thumb09 from '../assets/images/portrait/2015/09.jpg'
import thumb10 from '../assets/images/portrait/2015/10.jpg'
import thumb11 from '../assets/images/portrait/2015/11.jpg'
import thumb12 from '../assets/images/portrait/2015/12.jpg'
import thumb13 from '../assets/images/portrait/2015/13.jpg'
import thumb14 from '../assets/images/portrait/2015/14.jpg'
import thumb15 from '../assets/images/portrait/2015/15.jpg'
import thumb16 from '../assets/images/portrait/2015/16.jpg'
import thumb17 from '../assets/images/portrait/2015/17.jpg'
import thumb18 from '../assets/images/portrait/2015/18.jpg'
import thumb19 from '../assets/images/portrait/2015/19.jpg'
import pcthumb01 from '../assets/images/portrait/2015/thumbnail/01.jpg'
import pcthumb02 from '../assets/images/portrait/2015/thumbnail/02.jpg'
import pcthumb03 from '../assets/images/portrait/2015/thumbnail/03.jpg'
import pcthumb04 from '../assets/images/portrait/2015/thumbnail/04.jpg'
import pcthumb05 from '../assets/images/portrait/2015/thumbnail/05.jpg'
import pcthumb06 from '../assets/images/portrait/2015/thumbnail/06.jpg'
import pcthumb07 from '../assets/images/portrait/2015/thumbnail/07.jpg'
import pcthumb08 from '../assets/images/portrait/2015/thumbnail/08.jpg'
import pcthumb09 from '../assets/images/portrait/2015/thumbnail/09.jpg'
import pcthumb10 from '../assets/images/portrait/2015/thumbnail/10.jpg'
import pcthumb11 from '../assets/images/portrait/2015/thumbnail/11.jpg'
import pcthumb12 from '../assets/images/portrait/2015/thumbnail/12.jpg'
import pcthumb13 from '../assets/images/portrait/2015/thumbnail/13.jpg'
import pcthumb14 from '../assets/images/portrait/2015/thumbnail/14.jpg'
import pcthumb15 from '../assets/images/portrait/2015/thumbnail/15.jpg'
import pcthumb16 from '../assets/images/portrait/2015/thumbnail/16.jpg'
import pcthumb17 from '../assets/images/portrait/2015/thumbnail/17.jpg'
import pcthumb18 from '../assets/images/portrait/2015/thumbnail/18.jpg'
import pcthumb19 from '../assets/images/portrait/2015/thumbnail/19.jpg'
import Gallery from '../components/Gallery'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import Worksnav from '../components/Worksnav'


const DEFAULT_IMAGES = [
  {
    id: '1',
    source: portrait01,
    thumbnail: pcthumb01,
    caption: 'Photo 1',
    description: 'Kaito Yoshimura',
  },
  {
    id: '2',
    source: portrait02,
    thumbnail: pcthumb02,
    caption: 'Photo 2',
    description: 'Kousuke Yokota',
  },
  {
    id: '3',
    source: portrait03,
    thumbnail: pcthumb03,
    caption: 'Photo 3',
    description: 'Jyuri Ihata',
  },
  {
    id: '4',
    source: portrait04,
    thumbnail: pcthumb04,
    caption: 'Photo 4',
    description: 'Takuma Izumi',
  },
  {
    id: '5',
    source: portrait05,
    thumbnail: pcthumb05,
    caption: 'Photo 5',
    description: 'Takuma Izumi',
  },
  {
    id: '6',
    source: portrait06,
    thumbnail: pcthumb06,
    caption: 'Photo 6',
    description: 'Sakiko Kato',
  },
  {
    id: '7',
    source: portrait07,
    thumbnail: pcthumb07,
    caption: 'Photo 7',
    description: 'Fumiko Aoyagi',
  },
  {
    id: '8',
    source: portrait08,
    thumbnail: pcthumb08,
    caption: 'Photo 8',
    description: 'Fumiko Aoyagi',
  },
  {
    id: '9',
    source: portrait09,
    thumbnail: pcthumb09,
    caption: 'Photo 9',
    description: 'Narumi Muraki',
  },
  {
    id: '10',
    source: portrait10,
    thumbnail: pcthumb10,
    caption: 'Photo 10',
    description: 'Nijiro Murakami and Kiyo Matsumoto',
  },
  {
    id: '11',
    source: portrait11,
    thumbnail: pcthumb11,
    caption: 'Photo 11',
    description: 'Akari Mimura',
  },
  {
    id: '12',
    source: portrait12,
    thumbnail: pcthumb12,
    caption: 'Photo 12',
    description: 'Nijiro Murakami',
  },
  {
    id: '13',
    source: portrait13,
    thumbnail: pcthumb13,
    caption: 'Photo 13',
    description: '',
  },
  {
    id: '14',
    source: portrait14,
    thumbnail: pcthumb14,
    caption: 'Photo 14',
    description: 'Kiyo Matsumoto',
  },
  {
    id: '15',
    source: portrait15,
    thumbnail: pcthumb15,
    caption: 'Photo 15',
    description: 'Margaux',
  },
  {
    id: '16',
    source: portrait16,
    thumbnail: pcthumb16,
    caption: 'Photo 16',
    description: 'Sumire Ashina',
  },
  {
    id: '17',
    source: portrait17,
    thumbnail: pcthumb17,
    caption: 'Photo 17',
    description: 'Nijiro Murakami',
  },
  {
    id: '18',
    source: portrait18,
    thumbnail: pcthumb18,
    caption: 'Photo 18',
    description: 'Moe Miura',
  },
  {
    id: '19',
    source: portrait19,
    thumbnail: pcthumb19,
    caption: 'Photo 19',
    description: 'Kaito Yoshimura',
  },
]
const DEFAULT_IMAGES2 = [
  {
    id: '1',
    source: portrait01,
    thumbnail: thumb01,
    caption: 'Photo 1',
    description: 'Kaito Yoshimura',
  },
  {
    id: '2',
    source: portrait02,
    thumbnail: thumb02,
    caption: 'Photo 2',
    description: 'Kousuke Yokota',
  },
  {
    id: '3',
    source: portrait03,
    thumbnail: thumb03,
    caption: 'Photo 3',
    description: 'Jyuri Ihata',
  },
  {
    id: '4',
    source: portrait04,
    thumbnail: thumb04,
    caption: 'Photo 4',
    description: 'Takuma Izumi',
  },
  {
    id: '5',
    source: portrait05,
    thumbnail: thumb05,
    caption: 'Photo 5',
    description: 'Takuma Izumi',
  },
  {
    id: '6',
    source: portrait06,
    thumbnail: thumb06,
    caption: 'Photo 6',
    description: 'Sakiko Kato',
  },
  {
    id: '7',
    source: portrait07,
    thumbnail: thumb07,
    caption: 'Photo 7',
    description: 'Fumiko Aoyagi',
  },
  {
    id: '8',
    source: portrait08,
    thumbnail: thumb08,
    caption: 'Photo 8',
    description: 'Fumiko Aoyagi',
  },
  {
    id: '9',
    source: portrait09,
    thumbnail: thumb09,
    caption: 'Photo 9',
    description: 'Narumi Muraki',
  },
  {
    id: '10',
    source: portrait10,
    thumbnail: thumb10,
    caption: 'Photo 10',
    description: 'Nijiro Murakami and Kiyo Matsumoto',
  },
  {
    id: '11',
    source: portrait11,
    thumbnail: thumb11,
    caption: 'Photo 11',
    description: 'Akari Mimura',
  },
  {
    id: '12',
    source: portrait12,
    thumbnail: thumb12,
    caption: 'Photo 12',
    description: 'Nijiro Murakami',
  },
  {
    id: '13',
    source: portrait13,
    thumbnail: thumb13,
    caption: 'Photo 13',
    description: '',
  },
  {
    id: '14',
    source: portrait14,
    thumbnail: thumb14,
    caption: 'Photo 14',
    description: 'Kiyo Matsumoto',
  },
  {
    id: '15',
    source: portrait15,
    thumbnail: thumb15,
    caption: 'Photo 15',
    description: 'Margaux',
  },
  {
    id: '16',
    source: portrait16,
    thumbnail: thumb16,
    caption: 'Photo 16',
    description: 'Sumire Ashina',
  },
  {
    id: '17',
    source: portrait17,
    thumbnail: thumb17,
    caption: 'Photo 17',
    description: 'Nijiro Murakami',
  },
  {
    id: '18',
    source: portrait18,
    thumbnail: thumb18,
    caption: 'Photo 18',
    description: 'Moe Miura',
  },
  {
    id: '19',
    source: portrait19,
    thumbnail: thumb19,
    caption: 'Photo 19',
    description: 'Kaito Yoshimura',
  },
]

const siteTitle = 'Onodera Ryo | portrait'

const Portrait2015 = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      nonClass: '',
    }
  }
  toggleNon = () => {
    console.log("111");
    this.setState({
      nonClass: 'is-active',
    })
  }

  componentDidMount() {
    setTimeout(() => {
      this.toggleNon();
    },700);
  }

  render() {
      return (
      <Layout>
        <Helmet>
          <title>{siteTitle}</title>
        </Helmet>
        <div id="main">
          <div className="portrait">
            <Worksnav>
              Portrait 2015
            </Worksnav>
            <div class={`gall ${this.state.nonClass}`}>
            <MediaQuery query="(min-width: 767px)">
              <Gallery
                      images={DEFAULT_IMAGES.map(
                        ({ id, source, thumbnail, caption, description }) => ({
                          source,
                          thumbnail,
                          caption,
                          description,
                        })
                      )}
              />
            </MediaQuery>
            <MediaQuery query="(max-width: 767px)">
              <Gallery
                      images={DEFAULT_IMAGES2.map(
                        ({ id, source, thumbnail, caption, description }) => ({
                          source,
                          thumbnail,
                          caption,
                          description,
                        })
                      )}
              />
            </MediaQuery>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Portrait2015